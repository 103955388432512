import { useState, useEffect } from 'react';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';

export default function InnerLinkHelper({
  href,
  label
}: {
  href: string;
  label: string;
}) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1040);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1040);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <span className="typography">
      <Link
        to={href}
        style={{
          position: 'absolute',
          top: '1.2rem',
          right: 'var(--gap-S)',
          zIndex: 100
        }}
      >
        {isSmallScreen ? <Icon name="pen" /> : label}
      </Link>
    </span>
  );
}
